<template>
  <div>
    <div class="root_cont blue_bg" id="solutions_reports_table">
      <div class="container">
        <h2 class="text-center">
          <span v-html="t.head"></span>
        </h2>
        <div class="reports-row pt-4">
          <div class="side_txt_clmn">
            <div class="sm_text">
              <p>
                <span v-html="t.report_help_text"></span>
              </p>
            </div>
          </div>
          <div class="reports_clmn">
            <div class="d-flex">
              <div class="w-50 text-center">
                <div class="fw-600 report_head">
                  <span v-html="t.FREE"></span>
                </div>
              </div>
              <div class="w-50 text-center">
                <div class="fw-600 report_head">
                  <span v-html="t.PREMIUM"></span>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <div class="w-50 text-center mb-3">
                <div>
                  <div class="mt-3">
                    <img
                      class="border-shadow"
                      style="opacity: 1;"
                      width="160"
                      :src="
                        $lang === 'hr'
                          ? '/static/documents/ReportFree-HR.png'
                          : '/static/documents/ReportFree-ENG.png'
                      "
                    />
                  </div>
                  <div>
                    <a class="d-block mt-2" target="_blank" style="color: #fffff3;"
                        :href="`/${$lang}/free_report`"
                    >{{ t.view_sample }}</a>
                    <button
                      @click="toReportsOrRegistration"
                      style="background-color: gray"
                      class="btn mt-2"
                    >
                      <span v-if="true">{{ t.UNAVAILABLE }}</span>
                      <span
                        v-html="t.DOWNLOAD"
                        v-else-if="$store.state.auth.user_logged_in"
                      ></span>
                      <span v-html="t.REGISTER" v-else></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="w-50 text-center mb-3">
                <div class="mt-3">
                  <img
                      style="opacity: .3"
                    class="pr"
                    :width="160*1.11"
                    :src="
                      $lang === 'hr'
                        ? '/static/documents/ReportPremium-50-HR.png'
                        : '/static/documents/ReportPremium-50-ENG.png'
                    "
                  />
                </div>
                <div>
                  <a class="d-block mt-2" target="_blank" style="color: #fffff3;"
                     :href="`/${$lang}/premium_report`"
                  >{{ t.view_sample }}</a>
                  <button class="btn mt-2"
                          style="background-color: gray"
                  >
                    <span v-if="true">{{ t.UNAVAILABLE }}</span>
                     <span
                       v-html="t.ORDER"
                       v-else-if="$store.state.auth.user_logged_in"
                     ></span>
                    <span v-html="t.REGISTER" v-else></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table_cont sm_text">
          <div class="mobile-tabs">
            <div class="mobile-tabs-cont">
              <div class="mobile-tab">
                <span v-html="t.FREE"></span>
              </div>
              <div class="mobile-tab">
                <span v-html="t.PREMIUM"></span>
              </div>
            </div>
          </div>
          <div>
            <div class="table_header fw-600">
              <span v-html="t.t_1_h"></span>
            </div>
            <table class="">
              <tbody>
                <tr>
                  <td>
                    <span v-html="t.t_1_1"></span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_1_2"></span>
                  </td>
                  <td>
                    <svg-checkmark-fat
                      class="checkmark"
                    ></svg-checkmark-fat>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_1_3"></span>
                  </td>
                  <td>
                    <svg-checkmark-fat
                      class="checkmark"
                    ></svg-checkmark-fat>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_1_4"></span>
                  </td>
                  <td>
                    <svg-checkmark-fat
                      class="checkmark"
                    ></svg-checkmark-fat>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="table_header fw-600">
              <span v-html="t.t_2_h"></span>
            </div>
            <table class="">
              <tbody>
                <tr>
                  <td>
                    <span v-html="t.t_2_1"></span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_2_2"></span>
                  </td>
                  <td>
                    <span class="fw-600">
                      <span class="">{{ t.limited }}*</span>
                    </span>
                  </td>
                  <td>
                    <span>
                      <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_2_3"></span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                  <td>
                    <span>
                      <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_2_4"></span>
                  </td>
                  <td>
                    <span class="fw-600">
                      <span class="">{{ t.limited }}*</span>
                    </span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_2_5"></span>
                  </td>
                  <td>
                    <span class="fw-600">
                      <span class="">{{ t.limited }}*</span>
                    </span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="table_header fw-600">
              <span v-html="t.t_3_h"></span>
            </div>
            <table class="">
              <tbody>
                <tr>
                  <td>
                    <span v-html="t.t_3_1"></span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                  <td>
                    <span>
                      <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_3_2"></span>
                  </td>
                  <td>
                    <span class="fw-600">
                      <span class="">{{ t.limited }}*</span>
                    </span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_3_3"></span>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_3_4"></span>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_3_5"></span>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="table_header fw-600">
              <span v-html="t.t_4_h"></span>
            </div>
            <table class="">
              <tbody>
                <tr>
                  <td>
                    <span v-html="t.t_4_1"></span>
                  </td>
                  <td>
                    <span class="fw-600">
                      <span class="">{{ t.limited }}*</span>
                    </span>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_4_2"></span>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_4_3"></span>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-html="t.t_4_4"></span>
                  </td>
                  <td>
                    <svg width="10" height="10">
                      <circle cx="5" cy="5" fill="red" r="5"></circle>
                    </svg>
                  </td>
                  <td>
                    <svg-checkmark-fat class="checkmark"></svg-checkmark-fat>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="help_text mt-2">
            <span v-html="t.fusnote"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head": "OUR REPORTS",
    "COMING_SOON": "COMING SOON",
    "NEW": "NEW",
    "report_help_text": "Choose from either our Free or Premium Reports - depending on the level of detail that you are interested in.",
    "view_sample": "View Sample",
    "REGISTER": "REGISTER",
    "DOWNLOAD": "DOWNLOAD",
    "ORDER": "ORDER",
    "FREE": "FREE",
    "PREMIUM": "PREMIUM",
    "t_1_h": "General information",
    "t_1_1": "Your guide to Croatian land books",
    "t_1_2": "Your family name history",
    "t_1_3": "General facts about Croatia",
    "t_1_4": "Overview of Croatian emigration",
    "t_2_h": "Search results",
    "t_2_1": "Property search results summary",
    "t_2_2": "Location size and shape of parcel",
    "t_2_3": "Ownership probability summary",
    "t_2_4": "Ownership probability by parcel",
    "t_2_5": "GIS Data",
    "t_3_h": "Land records",
    "t_3_1": "Property category summary",
    "t_3_2": "Property category by parcel",
    "t_3_3": "Land-Register municipality and parcel no.",
    "t_3_4": "Cadastral possessory sheet and parcel no.",
    "t_3_5": "Median land prices",
    "t_4_h": "Ownership data",
    "t_4_1": "Number of co-owners",
    "t_4_2": "Detailed co-ownership",
    "t_4_3": "Ownership share",
    "t_4_4": "Ownership share area size",
    "limited": "LIMITED",
    "coming_soon": "COMING SOON",
    "UNAVAILABLE": "UNAVAILABLE",
    "fusnote": "* Top 10 parcels for each family member"
  },
  "hr": {
    "head": "Naša izvješća",
    "COMING_SOON": "DOLAZI USKORO",
    "NEW": "NOVO",
    "report_help_text": "Odaberite između našeg Besplatnog ili Premium izvješća – ovisno o razini detalja koji Vas zanimaju.",
    "view_sample": "Pogledajte primjerak",
    "REGISTER": "REGISTRIRAJ SE",
    "DOWNLOAD": "PREUZMI",
    "ORDER": "NARUČI",
    "FREE": "BESPLATNO",
    "PREMIUM": "PREMIUM",
    "t_1_h": "Opći podaci",
    "t_1_1": "Vaš vodič kroz zemljišne zapise",
    "t_1_2": "Povijest Vašeg prezimena",
    "t_1_3": "Opće činjenice o Hrvatskoj",
    "t_1_4": "Povijest hrvatskog iseljeništva",
    "t_2_h": "Rezultati pretraživanja",
    "t_2_1": "Sažetak rezultata pretraživanja zemljišta",
    "t_2_2": "Lokacija, veličina i oblik čestice",
    "t_2_3": "Pregled vjerojatnosti vlasništva",
    "t_2_4": "Vjerojatnost vlasništva za svaku česticu",
    "t_2_5": "GIS Podaci",
    "t_3_h": "Zemljišni zapisi",
    "t_3_1": "Pregled kategorija zemljišta",
    "t_3_2": "Kategorija zemljišta za svaku česticu",
    "t_3_3": "Zemljišnoknjižna općina i broj čestice",
    "t_3_4": "Katastarski posjedovni list i broj čestice",
    "t_3_5": "Medijalne cijene zemljišta",
    "t_4_h": "Podaci o vlasništvu",
    "t_4_1": "Broj suvlasnika",
    "t_4_2": "Detaljni pregled suvlasnika",
    "t_4_3": "Suvlasnički udio",
    "t_4_4": "Površina suvlasničkog udjela",
    "limited": "TOP 10",
    "coming_soon": "DOLAZI USKORO",
    "UNAVAILABLE": "NEDOSTUPNO",
    "fusnote": "* Prvih 10 čestica za svaku osobu iz obiteljskog stabla"
  },
  "es": {
    "head": "Informes",
    "free_report": "Informe gratuito",
    "more_detailed_report": "Informe Premium",
    "COMING_SOON": "PRÓXIMAMENTE",
    "NEW": "NUEVO",
    "report_help_text_1": "Locksmith apoya la venta final de terreno sin coste inicial para el propietario. Por consiguiente, no podemos prestar un servicio que establezca solamente un título limpio.",
    "report_help_text_2": "Para aquellos que deseen seguir esa vía, pronto lanzaremos un Informe Premium pagado, diseñado para ahorrar el tiempo y los costes incurridos por asesores jurídicos independientes.",
    "REGISTER": "REGÍSTRESE",
    "ORDER": "SOLICITAR",
    "FREE": "GRATUITO",
    "PREMIUM": "PREMIUM",
    "t_1_h": "Información general",
    "t_1_1": "Su guía del registro de la propiedad croata",
    "t_1_2": "Historial de su apellido",
    "t_1_3": "Datos generales sobre Croacia",
    "t_1_4": "Perspectiva general de la emigración croata",
    "t_2_h": "Resultados de la búsqueda",
    "t_2_1": "Resumen de los resultados de búsqueda de propiedades",
    "t_2_2": "Ubicación, tamaño y forma de la parcela",
    "t_2_3": "Resumen de la probabilidad de titularidad",
    "t_2_4": "Probabilidad de propiedad por parcela",
    "t_2_5": "Datos SIG",
    "t_3_h": "Registros de terrenos",
    "t_3_1": "Resumen de las categorías de parcelas",
    "t_3_2": "Categoría de cada parcela",
    "t_3_3": "Municipio del registro de la propiedad y el número de la parcela",
    "t_3_4": "Número de parcela catastral",
    "t_3_5": "Clasificación zonal",
    "t_4_h": "Datos de titularidad",
    "t_4_1": "Número de copropietarios",
    "t_4_2": "Copropietarios en detalle",
    "t_4_3": "Coeficiente de copropiedad",
    "t_4_4": "Tamaño del área de copropiedad",
    "limited": "LIMITADO",
    "coming_soon": "PRÓXIMAMENTE",
    "fusnote": "* 10 parcelas principales para cada miembro de la familia"
  }
}
//</i18n>

export default {
  name: "ReportsTable",
  data() {
    return {
      t: t[this.$lang]
    };
  },
  methods: {
    toReportsOrRegistration() {
      const vm = this;

      if (vm.$store.state.auth.user_logged_in) {
        vm.$router.push({ name: "portal_reports" });
      } else {
        vm.$store.dispatch("auth/openAuth", 2);
      }
    }
  }
};
</script>

<style scoped>
.root_cont {
  padding: 40px 0;
}

.btn {
  background-color: rgb(4, 43, 93);
  color: #fff;
}

label {
  font-weight: 600;
}

.reports-row {
  display: flex;
  flex-wrap: wrap;
  /*width: calc(100% - 14px);*/
}

.side_txt_clmn {
  width: 26%;
  padding-top: 80px;
}

.reports_clmn {
  width: 74%;
}

.report_head {
  font-size: 1.2rem !important;
}

.border-shadow {
  -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.5);
}

a {
  color: rgb(4, 43, 93);
  text-decoration: underline;
}

.help_text {
  font-size: 12px;
}

.sm_text {
  font-size: 14px;
}

.coming_soon_btn {
  border: solid #58619c 1px;
  color: #58619c;
  cursor: default;
}

.coming_soon_img {
  background-color: #fff;
  opacity: 0.5;
}

td {
  position: relative;
}

.new-tab {
  display: block;
  background-color: green;
  color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0px;
  left: 70%;
  padding: 3px 7px;
}

@media (max-width: 576px) {
  .mobile_break {
    display: block;
  }

  .side_txt_clmn {
    width: 100%;
    padding-top: 0;
  }

  .reports_clmn {
    width: 100%;
  }
}

.mobile-tabs {
  display: none;
}

.table_cont {
}

.sm_text {
  font-size: 14px;
}

.table_header {
  background-color: #58619c;
  color: #fff;
  padding: 4px 10px;
}

table {
  width: 100%;
  background-color: #fff;
  color: rgb(4, 43, 93);
}

table,
th,
td {
  border: 0.3px solid #58619c;
}

.cl-green {
  color: green;
}

.checkmark {
  width: 20px;
  color: green;
}

td {
  padding: 4px;
}

td:nth-child(1) {
  padding-left: 10px;
  padding-right: 10px;
  width: 24%;
}

td:nth-child(2) {
  width: 38%;
  text-align: center;
}

td:nth-child(3) {
  width: 38%;
  text-align: center;
  /*opacity: 0.3;*/
}

.help_text {
  font-size: 12px;
}

@media (max-width: 576px) {
  .mobile-tabs {
    display: block;
  }

  .mobile-tabs-cont {
    display: flex;
    padding-left: 46%;
  }

  .mobile-tab {
    width: 50%;
    text-align: center;
    border: 1px solid #58619c;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 4px;
    background-color: #fff;
    color: rgb(4, 43, 93);
  }

  td:nth-child(1) {
    padding-left: 3px;
    padding-right: 3px;
    width: 46%;
  }

  td:nth-child(2) {
    width: 27%;
    text-align: center;
  }

  td:nth-child(3) {
    width: 27%;
    text-align: center;
  }

  img {
    max-width: 80%;
  }

  img.pr {
    max-width: 89%;
  }

  .new-tab {
    display: block;
    position: absolute;
    left: initial;
    right: 5px;
    width: initial;
  }

}
</style>
