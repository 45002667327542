<template>
  <div class="WebReports">
    <WebReportsFirst />
    <ReportsTable />
  </div>
</template>

<script>
import WebReportsFirst from "@/components/web/reports/WebReportsFirst"
import ReportsTable from "@/components/web/reports/ReportsTable"
export default {
  name: "Locksmith",
  components: {
    ReportsTable,
    WebReportsFirst,
  }
};
</script>

<style lang="scss">
  .WebReports {
    .blue_bg_gradiant {
      background: linear-gradient(0deg, rgba(4, 43, 93, 0) 0, rgba(4, 43, 93, .3) 50px, rgba(4, 43, 93, 0.5) 70px, rgba(4, 43, 93, 0.8) 100%)
    }
  }
</style>
