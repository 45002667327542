<template>
  <div class="image blue_bg">
    <div class="blue_bg_gradiant">
      <div class="container">
        <div class="row pt-5 pb-5">
          <div class="col-12 col-md-6 mb-5">
            <h1 class="mt-4 fw-200">
              {{ t.h_1 }}
            </h1>
            <h5 class="fw-600">{{ t.h_2 }}</h5>
            <h6 class="fw-400">{{ t.h_3 }}</h6>
            <div>
              <span
                  @click="toReportsOrRegistration"
                  class="btn ta_disabled mt-2"
              >
                <span v-html="t.letsgo"></span>
              </span>
            </div>
          </div>
          <div class="col-12 col-md-6 pl-5 pr-5">
            <img :src="`/static/images/web_reports/Stablo${$lang === 'hr' ? 'HR' : ''}.png`" style="width: 100%" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
    //<i18n>
    {
      "en": {
        "h_1": "Could you have an Ancestral Claim to Land?",
        "h_2": "We can help you find out.",
        "h_3": "Download a copy of your free, fully personalized report on your family’s " +
            "possible claim to Croatian land - at zero obligation to yourself.",
        "letsgo": "LET'S GO"
      },
      "hr": {
        "h_1": "Jeste li naslijedili vlasnička prava na zemljište?",
        "h_2": "Možemo Vam pomoći da saznate.",
        "h_3": "Preuzmite primjerak svog besplatnog, potpuno personaliziranog izvješća o mogućem pravu na hrvatsko zemljište – bez ikakvih obveza.",
        "letsgo": "KRENIMO"
      },
      "es": {

      }
    }
//</i18n>


export default {
  name: "WebReportsFirst",
  data() {
    return {
      t: t[this.$lang]
    };
  },
  methods: {
    toReportsOrRegistration() {
      const vm = this;

      if (vm.$store.state.auth.user_logged_in) {
        vm.$router.push({ name: "portal_reports" });
      } else {
        vm.$store.dispatch("auth/openAuth", 2);
      }
    }
  }
};
</script>

<style scoped>

.image {
  background-position-x: center;
  background-position-y: 60%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-image: url("/static/images/web_reports/Naslovna.jpg"); /* The image used */
  z-index: 1;
}

.blue_bg_gradiant {
  background: linear-gradient(0deg, rgba(4, 43, 93, 0) 10%, rgba(4, 43, 93, .3) 25%, rgba(4, 43, 93, 0.5) 40%, rgba(4, 43, 93, 0.6) 50%, rgba(4, 43, 93, 0.7) 60%, rgba(4, 43, 93, 0.9) 100%)
}

h6 {
  line-height: 1.5;
}

.btn.green {
  background-color: #67c532;
  color: #fff;
  padding: 7px 60px;
}

@media (max-width: 576px) {
  .container {
    text-align: center;
  }
}

</style>
